<template>
  <div class="supportList">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>{{menu.title}}</h1>
          <p>
            <a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />
            <a href="/support">支持</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />{{menu.title}}
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <Row style="margin-bottom:40px">
            <Col
              span="18"
              style="padding-right:10px"
            >
            <input
              class="search"
              type="text"
              name="text"
              v-model="searchStr"
              placeholder="请输入您想查找的问题"
              required
            >
            </Col>            
            <Col span="6">
            <button
              type="submit"
              class="report-btn"
              @click="getList"
            ><span class="txt" >搜索</span></button>
            </Col>
          </Row>
          <h2>常见问题</h2>
          <ul>
            <li
              v-for="(item,index) in question"
              :key="index"
              @click="toContant(item.code)"
            >{{index + 1}}、{{item.title}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supportList from "./index.js";
export default supportList;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>